import React from 'react'
import PropTypes from 'prop-types'

import { graphql } from 'gatsby'

import NoLayout from '../components/layout/nolayout'

const TermsNoLayout = ({ data }) => (
  <NoLayout>
    <div dangerouslySetInnerHTML={{ __html: data.iubendaDocument.termsAndConditions }} />
  </NoLayout>
)

TermsNoLayout.propTypes = {
  data: PropTypes.shape({
    iubendaDocument: PropTypes.shape({
      termsAndConditions: PropTypes.string,
    }),
  }).isRequired,
}

export default TermsNoLayout

export const query = graphql`
query TermsNoLayout {
  iubendaDocument(documentId: {eq: "28834528"}) {
    termsAndConditions
  }
}
`
